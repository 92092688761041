import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Map from 'components/Map'


//export const query = graphql`
//query {
  //postgres {
    //allProducers(first: 10) {
      //nodes {
        //id
        //name
        //address
        //city
        //email
        //emphasis
      //}
    //}
  //}
//}
//`;

        //{data.postgres.allProducers.nodes.map((p, i) =>
          //<div key={`${p}|${i}`}>
            //{p.id}
            //{p.name}
            //{p.address}
          //</div>
        //)}
export default function IndexPage ({data}){
  return (
    <Layout>
      <SEO title="Burg Map" />
        <Map />
    </Layout>
  )
}

/*
const IndexPage = () => (
  <Layout>
    <SEO title="Home!" />
    <h1>Test 1</h1>
    <p>Welcome to your new Gatsby site.</p>
    <p>Now go build something great.</p>
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div>
    <Link to="/page-2/">Go to page 2</Link>
  </Layout>
)
*/
